import React from "react"
import { graphql } from "gatsby"

import { Layout, Document, SEO } from "../components"

interface PrismicDoc {
  id: string
  uid: string
  data: {
    title: {
      text: string
    }
    introduction: {
      html: string
    }
    content: Array<{
      slug: string
      section_title: {
        text: string
      }
      section_content: {
        html: string
      }
    }>
    last_modified: string
    version: string
  }
}

interface LegalDocumentProps {
  data: {
    prismicLegaldoc: PrismicDoc
  }
}

export default function LegalDocument({ data }: LegalDocumentProps) {
  const doc = data.prismicLegaldoc
  return (
    <Layout>
      <SEO title={doc.data.title.text} />
      <Document
        title={doc.data.title.text}
        version={doc.data.version}
        lastModified={doc.data.last_modified}
        introduction={doc.data.introduction.html}
        sections={doc.data.content.map(s => ({
          slug: s.slug,
          title: s.section_title.text,
          content: s.section_content.html,
        }))}
      />
    </Layout>
  )
}

export const query = graphql`
  query($uid: String!) {
    prismicLegaldoc(uid: { eq: $uid }) {
      id
      uid
      data {
        title {
          text
        }
        introduction {
          html
        }
        content {
          slug
          section_title {
            text
          }
          section_content {
            html
          }
        }
        last_modified
        version
      }
    }
  }
`
